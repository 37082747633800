<template>
  <div class="home" v-loading="loading">
    <el-row :gutter="20">
      <el-col :span="19">
        <el-table
          stripe
          class="mainTable"
          :data="deals"
        >
          <el-table-column  align="center" header-align="center" width="100px" prop="ID" label="ID"> </el-table-column>
          <el-table-column  align="center" header-align="center" prop="TITLE" label="Название"> </el-table-column>
          
              <el-table-column align="center" header-align="center"  prop="UF_CRM_5D55D5DBA8DFC" label="Дата платежа" :formatter="formatDate">
          </el-table-column> 
          <el-table-column  align="center" header-align="center" width="100px" prop="UF_CRM_1566315691" label="Маржа">
          </el-table-column>
          <el-table-column  align="center" header-align="center" width="100px" prop="UF_CRM_1635513653669" label="Эквайринг">
          </el-table-column>
          <el-table-column  align="center" header-align="center" width="150px" prop="UF_CRM_5D55D5DB9FBBE" label="Сумма логисту">
          </el-table-column>
          <el-table-column  align="center" header-align="center" prop="UF_CRM_1577277556" label="Передавшему сделку">
          </el-table-column>
        <el-table-column  align="center" header-align="center" width="200px" prop="UF_CRM_1577292616" label="Передавший сделку">
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="50" :current-page.sync="filter.page"></el-pagination>
      </el-col>
      <el-col :span="5">
        <el-form :model="filter">
          <el-form-item label="Дата поступления средств">
            <el-date-picker
      v-model="filter.period"
      @change="changeDate"
      type="datetimerange"
      align="right"
      unlink-panels
      :picker-options="pickerOptions" style="width: 100%">
    </el-date-picker>
          </el-form-item>
          <el-form-item label="Ответственный" v-if="this.user == 'Администратор'">
    <el-select clearable filterable v-model="filter.logist" placeholder="Выберите пользователя" style="width: 100%">
      <el-option :label="i.NAME + ' ' + i.LAST_NAME" :value="i.ID" v-for="(i, index) of logistList" :key="index"></el-option>
    </el-select>
  </el-form-item>
          <el-form-item label="Эквайринг">
    <el-switch v-model="filter.acquiring"></el-switch>
  </el-form-item>
        </el-form>
        <el-descriptions title="Итого"  direction="vertical">
    <el-descriptions-item label="Маржа"><el-tag size="small">{{this.sum[0]}}</el-tag></el-descriptions-item>
    <el-descriptions-item label="Сумма логисту"><el-tag size="small">{{this.sum[1]}}</el-tag></el-descriptions-item>
</el-descriptions>

  <el-button type="success" round style="width: 100%; margin-top: 20px;" @click="createReport(filter)" :loading="saving">Сформировать акт</el-button>
  <el-button type="danger" round style="width: 100%; margin-top: 10px; margin-left: 0px" @click="logout()">Выйти</el-button>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      filter: {
        period: [moment().subtract("2", "weeks"), moment()],
        page: this.$route.page || 1,
        acquiring: false
      },
    };
  },
  async created() {
    this.getReport(this.filter);
  },
  methods: {
    ...mapActions(["getReport", "createReport", "logout"]),
    setUser() {
      return this.user;
    },
    formatDate(r, c, val) {
      if(!val) return '';
      return moment(val).format("DD.MM.YYYY")
    },
    changeDate(e) {
      const date = e;
      const newDate = e.map(d => moment.utc(d).tz('Europe/Moscow'));
      console.log(date);
      console.log(newDate);
    }
  },
  computed: {
    ...mapGetters(["deals", "user", "total", "sum", "loading", "logistList", "saving"]),
  },
  watch: {
    filter: {
      handler(old, newVal) {
        this.getReport(newVal);
      },
      deep: true
    }
  }
};
</script>


<style>
.mainTable {
  width: 100%;
  height: calc(100vh - 130px);
  overflow-y: auto
}
.home {
  padding: 40px;
}
.el-pagination {
  padding-top: 20px;
  text-align: center
}
</style>